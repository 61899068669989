@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

.background-animate {
  background-size: 400%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}



.react-datepicker__aria-live {
  display: none !important;
  visibility: hidden !important;
}

.react-datepicker__input-container input {
  @apply block w-full z-50 text-base md:text-sm bg-white border-none rounded-md shadow  form-input focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-rose-500;
}

.react-datepicker-popper {
  @apply z-50 w-72 text-sm bg-white shadow-lg border border-gray-100 px-3 py-2 mt-4 rounded-md;
  z-index: 999999 !important;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
  z-index: 999999 !important;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
} 

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-rose-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-rose-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-rose-500;
}

.react-datepicker__day--selected {
  @apply bg-rose-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-rose-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-rose-500 text-white hover:text-gray-700 hover:bg-white;
}

/* React Tag input styles */

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 10px;
  margin-top: 0.5rem;
  display: inline-block;
  margin-left: 5px;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 40px;
  margin: 0;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  min-width: 200px;
}

.ReactTags__editInput {
  border-radius: 10px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ba7c7c;
  background-color: rgba(209 213 219, 0.5);
  border-radius: 10px;
  color: #ba7c7c;
  font-size: 14px;
  display: inline-block;
  padding: 8px;
  margin: 0 5px;
  transition: all 0.1s ease-in-out;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  margin-left: 5px;
  background: none;
  color: #ba7c7c;
}

#gradient-canvas {
  width:100%;
  height:100%;
  --gradient-color-1: #fff; 
  --gradient-color-2: #000; 
  --gradient-color-3: #7038ff;  
  --gradient-color-4: #ffba27;
  z-index:0;
}
